import { useEffect, useState } from 'react';
import axios from 'axios';

export default function useAPICall(
  method,
  url,
  headers,
  params,
  pageNum,
  sortOption,
  columnSort,
  globalFilters,
  callAPI = true,
  isDownloadRequest = false,
  requestBody = null
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState();
  const [fileName, setFileName] = useState();
  const [resp, setResp] = useState();
  const tokenObj = JSON.parse(localStorage.getItem('okta-token-storage'));
  const token = tokenObj?.accessToken?.accessToken;
  const correlationId = localStorage.getItem('correlationId');
  useEffect(() => {
    if (callAPI !== true) {
      setLoading(false);
      return null;
    }

    const cancelTokenSource = axios.CancelToken.source();
    if (url) {
      setLoading(true);
      setError(false);
      const obj = { ...params };
      const keys = Object.keys(obj);
      keys.forEach((key) => {
        if (obj[key])
          obj[key] = encodeURI(
            typeof obj[key] === 'string' ? obj[key] : JSON.stringify(obj[key])
          );
      });

      const apiRequestOptions = {
        method,
        url,
        params: { 
          ...obj,
          isPartner: localStorage.getItem('isPartner')
        },
        headers: {
          ...headers,
          'Authorization': `Bearer ${token}`,
          'email': tokenObj?.accessToken?.claims?.sub,
          'X-Correlation-Id': correlationId
        },
        cancelToken: cancelTokenSource.token,
        responseType: isDownloadRequest === true ? 'blob' : 'json',
      };

      if (requestBody !== null) {
        apiRequestOptions.data = requestBody;
      }

      axios(apiRequestOptions)
        .then((res) => {
          if (isDownloadRequest === true) {
            const disposition = res.headers['content-disposition'];

            if (disposition)
              setFileName(
                disposition.substring(
                  disposition.indexOf('fileName=') + 9,
                  disposition.length
                )
              );
          }

          setData(res.data);
          setLoading(false);
          setResp(res);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
          setLoading(false);
        });
      // Cancel request
      return () => {
        cancelTokenSource.cancel();
      };
    }
    return null;
  }, [
    headers,
    method,
    pageNum,
    url,
    sortOption,
    columnSort,
    globalFilters,
    callAPI,
    isDownloadRequest,
    requestBody,
  ]);

  return { loading, error, data, resp, fileName, setError, setData };
}